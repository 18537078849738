<template>
  <div>
    <!-- <b-card title="Oдобрене апликације">
      <h4 :style="text_style" style="margin-bottom: .7rem; font-size:1.12rem;" class="font-weight-lighter">
        Уколико сте преузели и активирали заштитну Беотаблет апликацију, али вам се нису појавиле све одобрене апликације на таблету, кликните
        на један од следећих линкова: <br><br>
        Уколико нисте преузели последњу верзију Андроид ОС за Ваш таблет - скенирајте <span class="hover-link" style="color: #305aef !important; text-decoration: underline;" v-b-modal.modal-and9>ОВАЈ</span> код. <br><br>
        Уколико сте преузели последњу верзију Андроид ОС за Ваш таблет - скенирајте <span class="hover-link" style="color: #305aef !important; text-decoration: underline;" v-b-modal.modal-and10>ОВАЈ</span> код. <br><br>
      </h4>
    </b-card> -->

    <b-card title="Предаја уређаја на сервис">
      <h4>

      </h4>
      
      <h4 :style="text_style" style="margin-bottom: .7rem; font-size:1.12rem;" class="font-weight-lighter">
          Уколико таблет манифестује квар односно неисправност, уређај можете предати на сервис на неколико локација у Београду.
        </h4>
      <div class='d-flex flex-column'>
        <div class=' p-0 align-self-start' style="border: 1px solid rgba(0,0,0, .125); border-radius: .2rem;">
          <div class='d-flex'>
            <div class='d-flex align-items-center justify-content-center' style="background: #305aef; border-top-left-radius: .2rem; border-bottom-left-radius: .2rem;">
              <feather-icon icon="InfoIcon" style="color:#fff; margin: .6rem;" :size="window_width < 550 ? '16' : '22' " />
            </div>
            <h4 v-if="window_width > 550" :style="text_style" style="padding: .6rem; font-size:1.12rem;" :class="{'sm-padding': window_width < 550}" class='font-weight-lighter m-0 d-flex align-items-center justify-content-center'>Кориснички сервис БеоТаблет – <h4 :style="text_style" style="font-weight:400; font-size:1.12rem; margin-left: .1rem" class='d-inline-block mb-0'>060/4064777</h4></h4>
            <div class='d-flex flex-column align-items-start'>
              <h4  v-if="window_width <= 550" :style="text_style" style="padding-top: .3rem ; font-size:1.12rem; margin-left: .4rem"  class='font-weight-lighter mb-0 d-flex align-items-center justify-content-center'> Кориснички сервис БеоТаблет - </h4>
              <h4  v-if="window_width <= 550" :style="text_style" style="font-weight:400; font-size:1.12rem; margin-left: .4rem; padding-right: .3rem; padding-bottom: .3rem" class='d-inline-block mb-0'>060/4064777</h4>
            </div>
          </div>
        </div>

        <div class=' p-0 mt-2 align-self-start' style="border: 1px solid rgba(0,0,0, .125); border-radius: .2rem;">
          <div class='d-flex'>
            <div class='d-flex align-items-center justify-content-center' style="background: #305aef; border-top-left-radius: .2rem; border-bottom-left-radius: .2rem;">
              <feather-icon icon="InfoIcon" style="color:#fff; margin: .6rem;" :size="window_width < 550 ? '16' : '22' " />
            </div>
            <h4 v-if="window_width > 550" :style="text_style" style="padding: .6rem; font-size:1.12rem;" :class="{'sm-padding': window_width < 550}" class='font-weight-lighter m-0 d-flex align-items-center justify-content-center'>Радно време: <h4 :style="text_style" style="font-weight:400; font-size:1.12rem; margin-left: .1rem" class='d-inline-block mb-0'>радним данима од 8-18, Суботом од 8-14</h4></h4>
            <div class='d-flex flex-column align-items-start'>
              <h4  v-if="window_width <= 550" :style="text_style" style="padding-top: .3rem ; font-size:1.12rem; margin-left: .4rem"  class='font-weight-lighter mb-0 d-flex align-items-center justify-content-center'>Радно време: </h4>
              <h4  v-if="window_width <= 550" :style="text_style" style="font-weight:400; font-size:1.12rem; margin-left: .4rem; padding-right: .3rem; padding-bottom: .3rem" class='d-inline-block mb-0'>радним данима од 8-18, Суботом од 8-14</h4>
            </div>
          </div>
        </div>
      </div>
      
      </b-card>
      <b-card title="Предаја уређаја директно у сервисни центар.">
      <div>
        <!-- <h4 style="margin-bottom: .7rem; font-size:1.12rem;"></h4> -->
        <h4 :style="text_style" style="margin-bottom: .7rem; font-size:1.12rem; line-height:22px;">
          Уколико сте у могућности, саветујемо да уређај предате директно у сервисни центар BG Elektronik.
        </h4>


        <h4 :style="text_style" style="margin-bottom: 1.7rem; font-size:1.12rem; line-height:22px;" class='font-weight-lighter'>
          Адреса сервиса је Дунавска бб. <br>
          Сервис се налази на Вилиним водама у Београду, иза Темпа у продужетку према Дунаву, са десне стране. <br>
          <a href="https://www.google.com/maps/place/BG+Elektronik/@44.825393,20.4839605,17z/data=!3m1!4b1!4m5!3m4!1s0x475a7a94585273e3:0xd23c1e3b52d3c902!8m2!3d44.8253892!4d20.4861545?hl=sr" target="_blank">Погледајте адресу на мапи</a>
        </h4>
        <h4 :style="text_style" style="margin-bottom: 1.7rem; font-size:1.12rem; line-height:22px;" class='font-weight-lighter'>
          Градски превоз: аутобуска линија 44, вози из правца Сењака до Дунав станице (недалеко од улице Деспота Стефана). <br> Линк до аутобуске линије на сајту ГСП-а: <a target="_blank" href="https://gsp.rs/linija.aspx?id=44">https://gsp.rs/linija.aspx?id=44</a>
        </h4>
        <h4 :style="text_style" style="margin-bottom: .7rem; font-size:1.12rem; line-height:22px;" class='font-weight-lighter'>
        </h4>
        <h4 :style="text_style" style="margin-bottom: .3rem; font-size:1.12rem; line-height:22px;" class='font-weight-lighter'>
          Контакт сервиса је: <h4 :style="text_style" class="m-0 d-inline-block">011-208-66-66</h4>
        </h4>
        <h4 :style="text_style" style="margin-bottom: .3rem; font-size:1.12rem; line-height:22px;" class='font-weight-lighter'>
          Имејл адреса: <h4 :style="text_style" class="m-0 d-inline-block">servis@bgelektronik.com</h4>
        </h4>
        <h4 :style="text_style" style="margin-bottom: 1.7rem; font-size:1.12rem; line-height:22px;" class='font-weight-lighter'>
          Радно време: <h4 :style="text_style" class="m-0 d-inline-block">од 08-18 радним данима. </h4>
        </h4>
        <h4 :style="text_style" style="margin-bottom: .7rem; font-size:1.12rem; line-height:22px;" class='font-weight-lighter'>
          Уз таблет обавезно понети реверс који сте добили приликом преузимања таблета. <br>
          Уколико сумњате на квар пуњења, молимо Вас да понесте и пуњач заједно са таблетом. <br>
          Молимо Вас да пре предаје таблета на сервис извадите СИМ и СД картицу, као и да уклоните лозинку за закључавање таблета (уколико је имате). <br>
          Уколико се приликом пријема неисправног уређаја у сервис установи да је у питању видљиво физичко оштећење на таблету (на пример поломљен дисплеј или просута течност), колеге из сервиса ће Вас замолити да, поред достављеног реверса, а зарад покретања полисе осигурања код Дунав осигурања, попуните одговарајући образац осигуравајуће куће, који сте добили уз таблет, и предате идентификациони документ (личну карту).
        </h4>
      </div>
      </b-card>
      <b-card title="Предаја уређаја на сервис на одабраним локацијама Поште Србије.">
      <div>
        <h4 :style="text_style" style="margin-bottom: .7rem; font-size:1.12rem; line-height:22px;" class='font-weight-lighter'>
           Урећај који има квар можете предати на сервисирање и у појединачним пословницама Поште Србије. Листу адреса пословница поште у које можете предати уређај можете пронаћи испод.
        </h4>
        <h4 :style="text_style" style="margin-bottom: .7rem; font-size:1.12rem; line-height:22px;">
          Напомена: само наведене пословнице ће примити Беотаблет на рекламацију.
        </h4><h4 :style="text_style" style="margin-bottom: .7rem; font-size:1.12rem; line-height:22px;" class='font-weight-lighter'>
           
        </h4>
        <h4 :style="text_style" style="margin-bottom: .7rem; font-size:1.12rem; line-height:22px;" class='font-weight-lighter'>
           
        </h4>
        <h4 :style="text_style" style="margin-bottom: .7rem; font-size:1.12rem; line-height:22px;" class='font-weight-lighter'>
        <h4 :style="text_style" style="font-size:1.12rem;" class='font-weight-lighter line-height:22px;'>Процедура приликом предаје уређаја са рекламацијом у пошти:</h4>
        <h4 :style="text_style" style="font-size:1.12rem;" class="font-weight-lighter ml-2 line-height:22px;"> - Доставити таблет са реверсом.</h4>
        <h4 :style="text_style" style="font-size:1.12rem;" class="font-weight-lighter ml-2 line-height:22px;"> - Уколико знате да је таблет физички оштећен обавезно доставити и документ Дунав осигурања (ПРИЈАВА ШТЕТЕ ОД ЛОМА И НЕКИХ ДРУГИХ ОПАСНОСТИ), како би се покренула полиса осигурања, као и очитану личну карту.</h4>
        <h4 :style="text_style" style="font-size:1.12rem;" class="font-weight-lighter ml-2 line-height:22px;"> - У пошти ћете попунити КАРТОН ПРИЈЕМА на ком ћете написати своје личне податке, основне податке о таблету и укратко описати квар који се манифестује.</h4>
        </h4>
      </div>

      <div :style="table_wrapper_style" >
        <table :style="table_style" style='width: 80rem;' class="support-table mt-3">
          <thead>
            <tr>
              <th :style="text_style" :class="{'sm-table-padding': window_width < 550}"> <div>Општина</div> </th>
              <th :style="text_style" :class="{'sm-table-padding': window_width < 550}"> <div>Поштански број </div></th>
              <th :style="text_style" :class="{'sm-table-padding': window_width < 550}"> <div>Адреса поште</div> </th>
              <th :style="text_style" :class="{'sm-table-padding': window_width < 550}" class="position-relative"> <div>Радним данима </div> </th>
              <!-- <div style="position:absolute; top: 0; right:-50%; transform: translateX(-50%)">Радно време</div> -->
              <th :style="text_style" :class="{'sm-table-padding': window_width < 550}"> <div>Суботом</div> </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td  style="padding: .5rem 1rem;"> <div>Нови Београд</div> </td>
              <td  > <div style="margin-bottom: .5rem; border-bottom: 1px solid rgba(0,0,0,.1); padding: .5rem 1rem;">11070 Београд 27 </div> <div style="padding: .3rem 1rem;">11197 Београд 119</div> </td>
              <td > <div style="margin-bottom: .5rem; border-bottom: 1px solid rgba(0,0,0,.1); padding: .5rem 1rem;">Генерала Жданова 2</div> <div style="padding: .3rem 1rem;">Јурија Гагарина 153а</div> </td>
              <td > <div style="margin-bottom: .5rem; border-bottom: 1px solid rgba(0,0,0,.1); padding: .5rem 1rem;">08:00 - 19:00</div>  <div style="padding: .3rem 1rem;">08:00 - 19:00</div></td>
              <td > <div style="margin-bottom: .5rem; border-bottom: 1px solid rgba(0,0,0,.1); padding: .5rem 1rem;"> 08:00 - 15:00 </div> <div style="padding: .3rem 1rem;"> 08:00 - 15:00 </div> </td>
            </tr>
            <tr>
              <td style="padding: .5rem 1rem;"> <div> Земун </div> </td>
              <td style="padding: .5rem 1rem;"> <div> 11080 Београд 80 </div> </td>
              <td style="padding: .5rem 1rem;"> <div> Главна 8 </div> </td>
              <td style="padding: .5rem 1rem;"> <div> 08:00 - 19:00 </div> </td>
              <td style="padding: .5rem 1rem;"> <div> 08:00 - 15:00 </div> </td>
            </tr>
            <tr>
              <td style="padding: .5rem 1rem;"> <div> Савски венац </div> </td>
              <td style="padding: .5rem 1rem;"> <div> 11000 Београд 6</div> </td>
              <td style="padding: .5rem 1rem;"> <div> Савска 2 </div> </td>
              <td style="padding: .5rem 1rem;"> <div> 08:00 - 19:00 </div> </td>
              <td style="padding: .5rem 1rem;"> <div> 08:00 - 19:00 и недељом </div> </td>
            </tr>

            <tr>
              <td style="padding: .5rem 1rem;"> <div>Стари град</div> </td>
              <td > <div style="margin-bottom: .5rem; border-bottom: 1px solid rgba(0,0,0,.1); padding: .5rem 1rem;">11158 Београд 118 </div> <div style="padding: .3rem 1rem;">11102 Београд 3</div> </td>
              <td> <div style="margin-bottom: .5rem; border-bottom: 1px solid rgba(0,0,0,.1); padding: .5rem 1rem;">Дубровачка 35</div> <div style="padding: .3rem 1rem;">Змај-Јовина 17</div> </td>
              <td> <div style="margin-bottom: .5rem; border-bottom: 1px solid rgba(0,0,0,.1); padding: .5rem 1rem;">08:00 - 19:00</div>  <div style="padding: .3rem 1rem;">08:00 - 19:00</div></td>
              <td> <div style="margin-bottom: .5rem; border-bottom: 1px solid rgba(0,0,0,.1); padding: .5rem 1rem;"> 08:00 - 15:00 </div> <div style="padding: .3rem 1rem;"> 08:00 - 15:00 </div> </td>
            </tr>

            <tr>
              <td style="padding: .5rem 1rem;"> <div>Врачар</div> </td>
              <td > <div style="margin-bottom: .5rem; border-bottom: 1px solid rgba(0,0,0,.1); padding: .5rem 1rem;">11118 Београд 32 </div> <div style="padding: .3rem 1rem;">11111 Београд 17</div> </td>
              <td> <div style="margin-bottom: .5rem; border-bottom: 1px solid rgba(0,0,0,.1); padding: .5rem 1rem;">Максима Горког 89</div> <div style="padding: .3rem 1rem;"> Булевар Краља Александра  </div> </td>
              <td> <div style="margin-bottom: .5rem; border-bottom: 1px solid rgba(0,0,0,.1); padding: .5rem 1rem;">08:00 - 19:00</div>  <div style="padding: .3rem 1rem;">08:00 - 19:00</div></td>
              <td> <div style="margin-bottom: .5rem; border-bottom: 1px solid rgba(0,0,0,.1); padding: .5rem 1rem;"> 08:00 - 15:00 </div> <div style="padding: .3rem 1rem;"> 08:00 - 15:00 </div> </td>
            </tr>

            <tr>
              <td style="padding: .5rem 1rem;"> <div>Вождовац</div> </td>
              <td > <div style="margin-bottom: .5rem; border-bottom: 1px solid rgba(0,0,0,.1); padding: .5rem 1rem;">11010 Београд 48 </div> <div style="padding: .3rem 1rem;">11107 Београд 11</div> </td>
              <td> <div style="margin-bottom: .5rem; border-bottom: 1px solid rgba(0,0,0,.1); padding: .5rem 1rem;"> Кумодрашка 153</div> <div style="padding: .3rem 1rem;"> Устаничка 79  </div> </td>
              <td> <div style="margin-bottom: .5rem; border-bottom: 1px solid rgba(0,0,0,.1); padding: .5rem 1rem;">08:00 - 19:00</div>  <div style="padding: .3rem 1rem;">08:00 - 19:00</div></td>
              <td> <div style="margin-bottom: .5rem; border-bottom: 1px solid rgba(0,0,0,.1); padding: .5rem 1rem;"> 08:00 - 15:00 </div> <div style="padding: .3rem 1rem;"> 08:00 - 15:00 </div> </td>
            </tr>

            <tr>
              <td style="padding: .5rem 1rem;"> <div>Звездара</div> </td>
              <td > <div style="margin-bottom: .5rem; border-bottom: 1px solid rgba(0,0,0,.1); padding: .5rem 1rem;">11050 Београд 22</div> <div style="padding: .3rem 1rem;">11160 Београд 74</div> </td>
              <td> <div style="margin-bottom: .5rem; border-bottom: 1px solid rgba(0,0,0,.1); padding: .5rem 1rem;"> Устаничка 182</div> <div style="padding: .3rem 1rem;"> Миријевски Венац 22а </div> </td>
              <td> <div style="margin-bottom: .5rem; border-bottom: 1px solid rgba(0,0,0,.1); padding: .5rem 1rem;">08:00 - 19:00</div>  <div style="padding: .3rem 1rem;">08:00 - 19:00</div></td>
              <td> <div style="margin-bottom: .5rem; border-bottom: 1px solid rgba(0,0,0,.1); padding: .5rem 1rem;"> 08:00 - 15:00 </div> <div style="padding: .3rem 1rem;"> 08:00 - 15:00 </div> </td>
            </tr>

            <tr>
              <td style="padding: .5rem 1rem;"> <div>Палилула</div> </td>
              <td > <div style="margin-bottom: .5rem; border-bottom: 1px solid rgba(0,0,0,.1); padding: .5rem 1rem;">11101 Београд 1</div> <div style="padding: .3rem 1rem;">11060 Београд 38</div> </td>
              <td> <div style="margin-bottom: .5rem; border-bottom: 1px solid rgba(0,0,0,.1); padding: .5rem 1rem;"> Таковска 2</div> <div style="padding: .3rem 1rem;"> Патриса Лумумбе 50 </div> </td>
              <td> <div style="margin-bottom: .5rem; border-bottom: 1px solid rgba(0,0,0,.1); padding: .5rem 1rem;">07:00 - 19:00</div>  <div style="padding: .3rem 1rem;">08:00 - 19:00</div></td>
              <td> <div style="margin-bottom: .5rem; border-bottom: 1px solid rgba(0,0,0,.1); padding: .5rem 1rem;"> 07:00 - 19:00 и недељом</div> <div style="padding: .3rem 1rem;"> 08:00 - 15:00 </div> </td>
            </tr>

            <tr>
              <td style="padding: .5rem 1rem;"> <div> Раковица </div> </td>
              <td style="padding: .5rem 1rem;"> <div> 11090 Београд 75</div> </td>
              <td style="padding: .5rem 1rem;"> <div> Пилота Михаила Петровића 8</div> </td>
              <td style="padding: .5rem 1rem;"> <div> 08:00 - 19:00 </div> </td>
              <td style="padding: .5rem 1rem;"> <div> 08:00 - 15:00 </div> </td>
            </tr>

            <tr>
              <td style="padding: .5rem 1rem;"> <div> Чукарица </div> </td>
              <td style="padding: .5rem 1rem;"> <div> 11030 Београд 8</div> </td>
              <td style="padding: .5rem 1rem;"> <div> Шумадијски трг 2а </div> </td>
              <td style="padding: .5rem 1rem;"> <div> 07:30 - 19:30 </div> </td>
              <td style="padding: .5rem 1rem;"> <div> 08:00 - 15:30 </div> </td>
            </tr>

            <tr>
              <td style="padding: .5rem 1rem;"> <div> Барајево </div> </td>
              <td style="padding: .5rem 1rem;"> <div> 11460 Барајево </div> </td>
              <td style="padding: .5rem 1rem;"> <div> Светосавска 4 </div> </td>
              <td style="padding: .5rem 1rem;"> <div> 08:00 - 19:00 </div> </td>
              <td style="padding: .5rem 1rem;"> <div> 08:00 - 14:00 </div> </td>
            </tr>

            <tr>
              <td style="padding: .5rem 1rem;"> <div> Гроцка </div> </td>
              <td style="padding: .5rem 1rem;"> <div> 11306 Гроцка </div> </td>
              <td style="padding: .5rem 1rem;"> <div> Булевар ослобођења 24 </div> </td>
              <td style="padding: .5rem 1rem;"> <div> 08:00 - 19:00 </div> </td>
              <td style="padding: .5rem 1rem;"> <div> 08:00 - 14:00 </div> </td>
            </tr>

            <tr>
              <td style="padding: .5rem 1rem;"> <div> Винча </div> </td>
              <td style="padding: .5rem 1rem;"> <div> 11351 Винча </div> </td>
              <td style="padding: .5rem 1rem;"> <div> Професора Васића 172 </div> </td>
              <td style="padding: .5rem 1rem;"> <div> 08:00 - 14:00 </div> </td>
              <td style="padding: .5rem 1rem;"> <div> 08:00 - 12:00 </div> </td>
            </tr>

            <tr>
              <td style="padding: .5rem 1rem;"> <div> Лазаревац </div> </td>
              <td style="padding: .5rem 1rem;"> <div> 11550 Лазаревац </div> </td>
              <td style="padding: .5rem 1rem;"> <div> Карађорђева 30 </div> </td>
              <td style="padding: .5rem 1rem;"> <div> 08:00 - 19:00 </div> </td>
              <td style="padding: .5rem 1rem;"> <div> 08:00 - 15:00 </div> </td>
            </tr>

            <tr>
              <td style="padding: .5rem 1rem;"> <div> Младеновац </div> </td>
              <td style="padding: .5rem 1rem;"> <div> 11400 Младеновац </div> </td>
              <td style="padding: .5rem 1rem;"> <div> Јанка Катића 10 </div> </td>
              <td style="padding: .5rem 1rem;"> <div> 08:00 - 19:00 </div> </td>
              <td style="padding: .5rem 1rem;"> <div> 08:00 - 15:00 </div> </td>
            </tr>

            <tr>
              <td style="padding: .5rem 1rem;"> <div> Обреновац </div> </td>
              <td style="padding: .5rem 1rem;"> <div> 11500 Обреновац </div> </td>
              <td style="padding: .5rem 1rem;"> <div> Милоша Обреновића 128 </div> </td>
              <td style="padding: .5rem 1rem;"> <div> 08:00 - 19:00 </div> </td>
              <td style="padding: .5rem 1rem;"> <div> 08:00 - 15:00 </div> </td>
            </tr>

            <tr>
              <td style="padding: .5rem 1rem;"> <div> Сопот </div> </td>
              <td style="padding: .5rem 1rem;"> <div> 11450 Сопот </div> </td>
              <td style="padding: .5rem 1rem;"> <div> Милосава Влајића 2 </div> </td>
              <td style="padding: .5rem 1rem;"> <div> 08:00 - 19:00 </div> </td>
              <td style="padding: .5rem 1rem;"> <div> 08:00 - 14:00 </div> </td>
            </tr>

            <tr>
              <td style="padding: .5rem 1rem;"> <div> Сурчин </div> </td>
              <td style="padding: .5rem 1rem;"> <div> 11271 Сурчин </div> </td>
              <td style="padding: .5rem 1rem;"> <div> Војвођанска 73 </div> </td>
              <td style="padding: .5rem 1rem;"> <div> 08:00 - 19:00 </div> </td>
              <td style="padding: .5rem 1rem;"> <div> 08:00 - 14:00 </div> </td>
            </tr>

            <tr>
              <td style="padding: .5rem 1rem;"> <div> Борча </div> </td>
              <td style="padding: .5rem 1rem;"> <div> 11211 Сурчин </div> </td>
              <td style="padding: .5rem 1rem;"> <div> Ваљевског одреда 15а </div> </td>
              <td style="padding: .5rem 1rem;"> <div> 08:00 - 19:00 </div> </td>
              <td style="padding: .5rem 1rem;"> <div> 08:00 - 13:00 </div> </td>
            </tr>

            <tr>
              <td style="padding: .5rem 1rem;"> <div> Сремчица </div> </td>
              <td style="padding: .5rem 1rem;"> <div> 11253 Сремчица </div> </td>
              <td style="padding: .5rem 1rem;"> <div> Београдска 157а </div> </td>
              <td style="padding: .5rem 1rem;"> <div> 08:00 - 19:00 </div> </td>
              <td style="padding: .5rem 1rem;"> <div> 08:00 - 14:30 </div> </td>
            </tr>

            <tr>
              <td style="padding: .5rem 1rem;"> <div> Врчин </div> </td>
              <td style="padding: .5rem 1rem;"> <div> 11224 Врчин </div> </td>
              <td style="padding: .5rem 1rem;"> <div> Саве Ковачевића 2 </div> </td>
              <td style="padding: .5rem 1rem;"> <div> 08:00 - 18:00 </div> </td>
              <td style="padding: .5rem 1rem;"> <div> 08:00 - 14:00 </div> </td>
            </tr>

            <tr>
              <td style="padding: .5rem 1rem;"> <div> Бели Поток </div> </td>
              <td style="padding: .5rem 1rem;"> <div> 11223 Бели Поток </div> </td>
              <td style="padding: .5rem 1rem;"> <div> Васе Чарапића 53 </div> </td>
              <td style="padding: .5rem 1rem;"> <div> 07:30 - 15:00 </div> </td>
              <td style="padding: .5rem 1rem;"> <div> 08:00 - 13:00 </div> </td>
            </tr>
          </tbody>
        </table>
      </div>
      
        
    </b-card>

    <b-modal id="modal-and10" ok-title="У реду" :hide-footer="true" centered size="s" title="QR код">
      <div style="text-align:center;">
        <img class="qr-img" src="../assets/images/qr_code/latest_TB-X606X.png" alt="">
        <span>Андроид 10</span>
      </div>
    </b-modal>

    <b-modal id="modal-and9" ok-title="У реду" :hide-footer="true" centered size="s" title="QR код">
      <div style="text-align:center;">
        <img class="qr-img" src="../assets/images/qr_code/original_TB-X606X.png" alt="">
        <span>Андроид 9</span>
      </div>
    </b-modal>


  </div>
</template>

<script>
import {BCard, BModal, VBModal} from 'bootstrap-vue'
export default {
  components: {
    BCard,
    BModal, 
    VBModal
  },
  created() {
    this.window_width = window.innerWidth
    window.addEventListener('resize', () => {
      this.window_width = window.innerWidth;
      console.log(this.window_width)
    })
  },
  data() {
    return {
       center: { 
        lat: 44.824972,
        lng: 20.486064
      },
      locationMarkers: [
        {
          lat: 44.824972,
          lng: 20.486064 
        }
      ],
      window_width: null
    }
  },
  computed: {
    text_style() {
      if(this.window_width > 320 && this.window_width < 650) {
        return {
          'font-size': '.95rem'
        }
      }
    },
    table_style() {
      if(this.window_width <= 1000) {
        return {
          'width': '908px'
        }
      }
    },
    table_wrapper_style() {
      if(this.window_width < 988) {
        return {
          'overflow-x': 'scroll',
        }
      }
    }
  },
}
</script>

<style lang="scss" scoped>

  .hover-link:hover {
    cursor: pointer;
  }

  .support-table {
    color:#6e6b7b;
    width:100%;
    border-collapse: collapse;
  }
  .table-wrapper {
    overflow-x: auto;
    width:100%;
    display:block;
  }
  .support-table td,
  .support-table th {
    // white-space:nowrap;
  }
  .support-table tbody tr td {
    // padding: 0.5rem 1rem;
    border: 1px solid rgba(0,0,0, .085)
  }

  .qr-img {
    width: 100%;
    height: 100%;
  }

  .support-table th {
    outline:none;
    background: #305aef;
    padding: 1.2rem 1rem;
    color:#fff;
  }

  .sm-padding {
    padding: .35rem!important;
  }
  .sm-table-padding {
    padding: .55rem!important;
  }
  .sm-text-margin {
    margin-left: .3rem;
  }
</style>